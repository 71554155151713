
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import DrawingBoard from "@/components/DrawingBoard.vue";
function EndOfStep() {
  return import(/* webpackChunkName: "end-of-step" */ "./EndOfStep.vue");
}
import CameraCapture from "@/components/CameraCapture/CameraCapture.vue";

import { getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyError,
  notifyInfo,
} from "@/components/Notification";
import rules from "@/components/account/rules";
import Errors from "./Errors.vue";
import { AxiosError } from "axios";
import { apiBaseUrl } from "@/enviorment";
import months from "@/utils/enums/months";
import DocumentViewer from "./DocumentViewer.vue";
import TokenExpired from "./TokenExpired.vue";
import { RequestBodyStep1 } from "@/models/BodyRequestStep";
import { mapGetters, mapState } from "vuex";
export default Vue.extend({
  name: "step1",
  components: {
    // DocumentViewer,
    DrawingBoard,
    Errors,
    EndOfStep,
    CameraCapture,
    DocumentViewer,
    TokenExpired,
  },
  data() {
    return {
      log: "",
      formValid: false,
      stepSended: false,
      showDocuments: false,
      expDatamenu: false,
      noCard: false,
      iampatient: false,
      expData: "",
      loading: false,
      cardType: 0,
      signature: new Blob(),
      holdercardsignature: new Blob(),
      showErrors: false,
      errorMessage: "",
      expireYear: 0,
      expireMonth: 0,
      noOwner: false,
      cards: [
        {
          type: "VISA",
          img: "visa-logo.png",
        },
        {
          type: "MASTERCARD",
          img: "mc_symbol.svg",
        },
        {
          type: "AMEX",
          img: "amex-logo.png",
        },
        {
          type: "DISCOVER",
          img: "discover-logo.png",
        },
      ],
      stepBody: {
        patientInitials: "",
        type: "VISA",
        holderName: "",
        number: "", //4242424242424242
        expire: new Date().toISOString().substr(0, 7),
        cvv: 0,
        zipCode: "",
        fullname: "",
        token: "",
      },
      stepBody1: {
        firstname: "",
        type: "VISA",
        holderName: "",
        number: "", //4242424242424242
        expire: new Date().toISOString().substr(0, 7),
        cvv: 0,
        zipCode: "",
        fullname: "",
        token: "",
      },
      rules,
      months: months,
      photoId: new Blob(),
      uploadAs: null,
      patientData: null,
      tokenExpired: false,
    };
  },
  computed: {
    ...mapGetters(["getpatientN"]),
    maxWidth(): number | string {
      const { xs, sm, md } = this.$vuetify.breakpoint;
      let result: number | string = "50%";

      if (xs) result = "100%";
      if (sm) result = "90%";
      if (sm) result = "90%";
      if (md) result = "80%";

      return result;
    },
    canSubmit(): boolean {
      const haveSignature = this.signature.size > 0;
      const haveSignatureHolder = this.holdercardsignature.size > 0;
      if (this.noCard) {
        return haveSignature;
      } else {
        return (
          haveSignature &&
          this.havePhotoId &&
          this.formValid &&
          haveSignatureHolder
        );
      }
    },
    years(): number[] {
      const result: number[] = [];
      const currentYer = parseInt(new Date().toISOString().split("-")[0]);

      for (let index = 1; index <= 10; index++) {
        result.push(currentYer + index);
      }

      return result;
    },
    documents(): any[] {
      return [
        {
          title: this.getpatientN + " contract",
          url: `${apiBaseUrl}/patient/getPdfDorisContracts/${this.stepBody.token}`,
        },
        {
          title: "Card contract",
          url: `${apiBaseUrl}/patient/getPdfCardContracts/${this.stepBody.token}`,
        },
      ];
    },
    havePhotoId(): boolean {
      const havePhotoId =
        (this.photoId instanceof Blob && this.photoId.size > 0) ||
        (this.photoId instanceof File && this.photoId.size > 0);

      return havePhotoId;
    },
    cardTypeChequed(): string {
      let result = "none";

      if (this.rules.isVisaCard(this.stepBody.number) === true) {
        result = "VISA";
      }
      if (this.rules.isMasterCard(this.stepBody.number) === true) {
        result = "MASTERCARD";
      }
      if (this.rules.isAmex(this.stepBody.number) === true) {
        result = "AMEX";
      }
      if (this.rules.isDiscover(this.stepBody.number) === true) {
        result = "DISCOVER";
      }

      return result;
    },
    isMobile(): boolean {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          // eslint-disable-next-line no-useless-escape
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || (window as any).opera);

      return check;
    },
  },
  watch: {
    uploadAs() {
      this.photoId = new Blob();
    },
    cardType() {
      this.stepBody.number = "";
    },
  },
  mounted() {
    this.checkToken();
  },
  methods: {
    sendData: async function (): Promise<any> {
      const expireISO = new Date(
        parseInt((this as any).expireYear),
        parseInt((this as any).expireMonth),
        1
      ).toISOString();

      this.stepBody.type = this.cardTypeChequed;
      this.stepBody.expire = expireISO;

      let body: RequestBodyStep1 = {};
      if (this.noCard) {
        body.fullname = this.stepBody.fullname;
        body.token = this.stepBody.token;
        body.patientInitials = this.stepBody.patientInitials;
        body.noCard = true;
      } else {
        body = {
          cardDto: {
            type: this.stepBody.type,
            holderName: this.stepBody.holderName,
            number: this.stepBody.number,
            expire: this.stepBody.expire,
            cvv: this.stepBody.cvv,
            zipCode: this.stepBody.zipCode,
            imOwner: this.noOwner ? false : true,
          },
          fullname: this.stepBody.fullname,
          patientInitials: this.stepBody.patientInitials,
          token: this.stepBody.token,
          noCard: false,
        };
      }

      const formData = new FormData();
      const formData1 = new FormData();
      // const expire = `${this.expireYear}-${this.expireMonth}-1`;

      formData.append(
        "firma",
        this.signature,
        `signature-${this.stepBody.token}`
      );

      try {
        this.loading = true;
        await getAPI.post("/patient/signStep1", body);
        await this.uploadPhotoId();
        await getAPI({
          method: "post",
          url: `/patient/uploadPatienSign/${this.stepBody.token}/STEP_1`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (this.noOwner) {
          formData1.append(
            "firma",
            this.holdercardsignature,
            `signature-${this.stepBody.token}`
          );
          await getAPI({
            method: "post",
            url: `/patient/uploadCardOwnerSign/${this.stepBody.token}`,
            data: formData1,
            headers: { "Content-Type": "multipart/form-data" },
          });
        }

        this.loading = false;
        this.stepSended = true;
        notifyInfo("Your request has been Sent for revision");
      } catch (error: any) {
        (this as any).log = error;

        let mess = error.response.data.message;

        if (mess.includes("[")) {
          mess = mess.replace("[", "");
          mess = mess.replace("]", "");
        }
        notifyError(error.response.data, `An error occurred: ${mess}`);

        this.loading = false;
      }
    },
    async uploadPhotoId(): Promise<any> {
      const formDataPhotoId = new FormData();
      if (this.noCard) {
        return;
      }
      formDataPhotoId.append(
        "photo",
        this.photoId,
        `photo-id-${this.stepBody.token}`
      );

      await getAPI({
        method: "post",
        url: `/patient/uploadPhotoAuthForm/${this.stepBody.token}`,
        data: formDataPhotoId,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(() => {
        if ((this as any).$refs.camera) {
          (this as any).$refs.camera.closeCamera();
        }
      });
    },
    onFileUpload(file: File) {
      if (file == null) {
        return;
      }
      const { name } = file;

      if (
        !name.includes(".png") &&
        !name.includes(".jpg") &&
        !name.includes(".jpeg")
      ) {
        notifyError(
          "The image format is not allowed, select <b>.png</b>, <b>.jpg</b> or <b>.jpeg</b>"
        );
        this.photoId = new Blob();
      }
    },
    async checkToken() {
      try {
        await getAPI("/patient/checkToken/" + this.$route.params.contractUuid);
        this.stepBody.token = this.$route.params.contractUuid;
        this.patientData = (
          await getAPI("/patient/getContractBytoken/" + this.stepBody.token)
        ).data.data;
        (this as any).$refs.form.validate();
      } catch (error) {
        if ((error as AxiosError).response?.status == 404) {
          this.tokenExpired = true;
        }
      }
    },
  },
});
